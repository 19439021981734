import * as u from '@jsmanifest/utils'
import React, { useState } from 'react'
import { css } from '@emotion/react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Sandpack } from '@codesandbox/sandpack-react'

export interface SandpackPageProps {
  //
}

const sampleCode = `
function sayHello() {
	console.log('hello')
}

sayHello()

console.log('We did it!')
`

function SandpackPage(props: React.PropsWithChildren<SandpackPageProps>) {
  useState
  return (
    <Sandpack
      customSetup={
        {
          //
        }
      }
      files={{
        'Sample.js': sampleCode,
      }}
      options={{
        // editorHeight: 200,
        // editorWidthPercentage: 60,
        recompileDelay: 150,
        showInlineErrors: true,
        showLineNumbers: true,
        showNavigator: true,
      }}
      template="react"
      theme={{
        palette: {
          activeText: '#c4a0fc',
          defaultText: '#7886b1',
          inactiveText: '#383d50',
          activeBackground: '#353f4e',
          defaultBackground: '#282a36',
          inputBackground: '#f8f8f2',
          accent: '#f8f8f2',
          errorBackground: '#ff79c6',
          errorForeground: '#cb6da3',
        },
        syntax: {
          plain: '#f8f8f2',
          keyword: '#ff79c6',
          comment: '#5e6c94',
          tag: '#ffdb71',
          punctuation: '#cedddb',
          definition: '#86dbff',
          property: '#c3a2fd',
          static: '#bd93f9',
          string: '#dbd0b5',
        },
        typography: {
          bodyFont:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          monoFont:
            '"Fira Mono", "DejaVu Sans Mono", Menlo, Consolas, "Liberation Mono", Monaco, "Lucida Console", monospace',
          fontSize: '13px',
          lineHeight: '1.4',
        },
      }}
    />
  )
}

export default SandpackPage
